import { onDomReady } from '../../components/dynamic/observer';
import axios from 'axios';


class ChatWidget {
  constructor(elements) {
    this.url = '/profile/api/no-read-messages';
    this.elements = elements;
    this.init();
  }

  init() {
    this.request();
  }

  request() {
    const instance = this;
    axios.get(this.url).then((response) => {
      this.replace(response.data)
    });
  }

  replace(content) {
    const div = document.createElement('div');
    div.innerHTML = content;

    const count = div.querySelectorAll('li').length;

    this.elements.forEach((element) => {
      const list = element.querySelector('[data-list]');
      const counter = element.querySelector('[data-counter]');
      list.innerHTML = div.innerHTML;
      if (count) {
        counter.innerHTML = count;
        counter.classList.add('_has-count');
      } else {
        counter.innerHTML = '';
        counter.classList.remove('_has-count');
      }
    });
  }
}
onDomReady(() => {
  const elements = document.querySelectorAll('[data-chat-widget]');
  if (elements.length > 0) {
    new ChatWidget(elements);
  }
});