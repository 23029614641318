var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stat-table", class: { _loading: _vm.rows === null } },
    [
      _vm.name
        ? _c("div", { staticClass: "stat-table__title" }, [
            _vm._v("\n    " + _vm._s(_vm.name) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "stat-table__form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateTable.apply(null, arguments)
            },
          },
        },
        [
          _c("base-field-checkbox", {
            staticClass: "stat-table__now",
            attrs: {
              label: "Текущий онлайн",
              "input-id": _vm.getFieldId("now"),
              "current-value": _vm.isNow,
            },
            on: { change: _vm.updateTable },
            model: {
              value: _vm.isNow,
              callback: function ($$v) {
                _vm.isNow = $$v
              },
              expression: "isNow",
            },
          }),
          _vm._v(" "),
          _c("base-field-date", {
            staticClass: "stat-table__field",
            attrs: {
              label: "От",
              "input-id": _vm.getFieldId("from"),
              "enable-time": true,
              disabled: _vm.isNow,
            },
            on: { change: _vm.updateTable },
            model: {
              value: _vm.dateFrom,
              callback: function ($$v) {
                _vm.dateFrom = $$v
              },
              expression: "dateFrom",
            },
          }),
          _vm._v(" "),
          _c("base-field-date", {
            staticClass: "stat-table__field",
            attrs: {
              label: "До",
              "input-id": _vm.getFieldId("to"),
              "enable-time": true,
              disabled: _vm.isNow,
            },
            on: { change: _vm.updateTable },
            model: {
              value: _vm.dateTo,
              callback: function ($$v) {
                _vm.dateTo = $$v
              },
              expression: "dateTo",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stat-table__content" },
        [
          _vm.rows === null || _vm.rows === undefined
            ? _c("Preloader")
            : _vm.rows.length
            ? _c(
                "ul",
                { staticClass: "stat-table__rows" },
                _vm._l(_vm.rows, function (row) {
                  return _c("li", { staticClass: "stat-table__row" }, [
                    _c("div", { staticClass: "stat-table__key" }, [
                      _c(
                        "a",
                        {
                          staticClass: "stat-table__link",
                          attrs: {
                            href: "/manager/clients/" + row.id,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n            ID " +
                              _vm._s(row.id) +
                              ": " +
                              _vm._s(row.name) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "stat-table__value" }, [
                      _vm._v(
                        "\n          " + _vm._s(row.organization) + "\n        "
                      ),
                    ]),
                  ])
                }),
                0
              )
            : _c("div", { staticClass: "stat-table__empty" }, [
                _vm._v("\n      Нет данных\n    "),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }