<template>
  <div class="stat-page__inner-content">
    <div class="stat-page__row">
      <div class="stat-page__graph_large">
        <ProductTypedGraph graph-key="client_prices_by_day_graph" :title="'Цены по клиентам'"/>
      </div>
      <div class="stat-page__graph_large">
        <ProductTypedGraph graph-key="client_prices_total_by_day_graph" :title="'Всего цен по клиентам'"/>
      </div>
    </div>

    <div class="stat-page__row">
      <ProductCountTable
          name="Просмотры клиентов"
          table-key="client_views_table"
      />
      <ProductCountTable
          name="Добавления в корзину"
          table-key="client_carts_table"
      />
      <ProductCountTable
          name="Оформления заказов"
          table-key="client_orders_table"
      />
    </div>
  </div>
</template>

<script>
import ProductCountTable from "./tables/ProductCountTable.vue";
import BaseGraph from "./graphs/BaseGraph.vue";
import ProductTypedGraph from "./graphs/ProductTypedGraph.vue";

export default {
  name: "TheStatClientGeneral",
  components: {
    BaseGraph,
    ProductCountTable,
    ProductTypedGraph
  },
}
</script>
