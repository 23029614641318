<template>
  <div class="stat-page__inner-content">
    <div class="stat-page__row">
      <div class="stat-page__graph_large">
        <BaseGraph graph-key="clients_by_day_graph"/>
      </div>
    </div>
    <div class="stat-page__row">
      <div class="stat-page__large-block">
        <ClientActivityTable
            name="Список активных клиентов"
            table-key="client_activity_table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseGraph from "./graphs/BaseGraph.vue";
import ClientActivityTable from "./tables/ClientActivityTable.vue";

export default {
  name: "TheStatClientActivity",
  components: {
    BaseGraph,
    ClientActivityTable
  },
}
</script>
