var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stat-page__content" },
    [
      _c("ul", { staticClass: "stat-page__tabs tabs tabs_black" }, [
        _c(
          "li",
          {
            staticClass: "tabs__item",
            class: { _active: _vm.currentTab === "general" },
          },
          [
            _c(
              "a",
              {
                staticClass: "tabs__link",
                attrs: { href: "javascript:void(0);" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTab("general")
                  },
                },
              },
              [_vm._v("\n        Общая\n      ")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "tabs__item",
            class: { _active: _vm.currentTab === "client" },
          },
          [
            _c(
              "a",
              {
                staticClass: "tabs__link",
                attrs: { href: "javascript:void(0);" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTab("client")
                  },
                },
              },
              [_vm._v("\n        По клиенту\n      ")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "tabs__item",
            class: { _active: _vm.currentTab === "activity" },
          },
          [
            _c(
              "a",
              {
                staticClass: "tabs__link",
                attrs: { href: "javascript:void(0);" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTab("activity")
                  },
                },
              },
              [_vm._v("\n        Активность\n      ")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.currentTab === "general"
        ? _c("TheStatClientGeneral")
        : _vm.currentTab === "client" && !_vm.currentClient
        ? _c("div", { staticClass: "stat-page__links-block" }, [
            _c(
              "ul",
              { staticClass: "stat-page__links-block-list" },
              _vm._l(_vm.clientList, function (client) {
                return _c(
                  "li",
                  { staticClass: "stat-page__links-block-item" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "stat-page__links-block-link",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.setCurrentClient(client.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(client.name) + "\n        "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm.currentTab === "client" && _vm.currentClient
        ? _c("TheStatClientInstance")
        : _vm.currentTab === "activity"
        ? _c("TheStatClientActivity")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }