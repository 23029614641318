var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__inner-content" }, [
    _c("div", { staticClass: "stat-page__row" }, [
      _c(
        "div",
        { staticClass: "stat-page__graph_large" },
        [_c("BaseGraph", { attrs: { "graph-key": "clients_by_day_graph" } })],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "stat-page__row" }, [
      _c(
        "div",
        { staticClass: "stat-page__large-block" },
        [
          _c("ClientActivityTable", {
            attrs: {
              name: "Список активных клиентов",
              "table-key": "client_activity_table",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }